(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/services/remove-template.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/services/remove-template.js');
"use strict";


const {
  jupiter
} = svs.core;
const removeTemplate = (_ref, callback) => {
  let {
    gameTemplateId
  } = _ref;
  jupiter.delete("/marketplace/1/containers/".concat(gameTemplateId),
  () => {
    callback();
  }, callback);
};
setGlobal('svs.components.marketplaceRetailAdminData.createGameTemplate.services.removeTemplate', removeTemplate);

 })(window);