(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/services/create-game-template.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/services/create-game-template.js');
"use strict";


const {
  helpers
} = svs.components.marketplaceRetailAdminData.gameTemplates;
const {
  jupiter
} = svs.core;
const {
  resolveProductId
} = svs.utils.products;
const createGameTemplate = (groupId, gameTemplate, callback) => {
  jupiter.post({
    path: "/retailer/1/marketplace/groups/".concat(groupId, "/containers"),
    data: {
      name: gameTemplate.name,
      editorId: gameTemplate.ownerId,
      description: gameTemplate.description ? gameTemplate.description : '',
      productId: resolveProductId(gameTemplate.productName),
      sharePrice: gameTemplate.fractionPrice,
      numShares: gameTemplate.maxFractions,
      commissionPercent: gameTemplate.commissionPercent,
      defaultBuyinCloseMinBeforeRegclose: gameTemplate.buyStopDiff,
      isAutoOpened: gameTemplate.isAutoOpened
    }
  }, gt => callback(null, helpers.normalizeGameTemplate(gt)), err => callback(err.responseJSON.error));
};
setGlobal('svs.components.marketplaceRetailAdminData.createGameTemplate.services.createGameTemplate', createGameTemplate);

 })(window);