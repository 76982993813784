(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/helpers/calculate-commission.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/helpers/calculate-commission.js');
'use strict';

const SHARE_MAX_COMMISSION = 150;
const calculateMaxPlayableAmount = (fractionPrice, commissionPercent, maxFractions) => {
  const commissionDivider = 1 + commissionPercent / 100;
  const maxPlayableAmount = Math.max(maxFractions * fractionPrice / commissionDivider, maxFractions * (fractionPrice - SHARE_MAX_COMMISSION));
  return Math.floor(Math.round(maxPlayableAmount * 100) / 100);
};
const calculateFractionPrice = (maxPlayableAmount, commissionPercent, maxFractions) => {
  const sharePlayableAmount = maxPlayableAmount / maxFractions;
  const shareCommission = Math.min(sharePlayableAmount * (commissionPercent / 100), SHARE_MAX_COMMISSION);
  const fractionPrice = sharePlayableAmount + shareCommission;
  return Math.ceil(Math.round(fractionPrice * 100) / 100);
};
const calculateCommission = _ref => {
  let {
    fractionPrice,
    maxFractions,
    maxPlayableAmount,
    commissionPercent
  } = _ref;
  if (isNaN(maxFractions) || maxFractions === null || isNaN(commissionPercent) || isNaN(fractionPrice) && isNaN(maxPlayableAmount)) {
    return {};
  }
  if (maxPlayableAmount > 0) {
    const calculatedFractionPrice = calculateFractionPrice(maxPlayableAmount, commissionPercent, maxFractions);
    return {
      fractionPrice: calculatedFractionPrice,
      maxFractions,
      maxPlayableAmount,
      commissionPercent
    };
  }
  if (fractionPrice > 0) {
    const calculatedMaxPlayableAmount = calculateMaxPlayableAmount(fractionPrice, commissionPercent, maxFractions);
    return {
      fractionPrice,
      maxFractions,
      maxPlayableAmount: calculatedMaxPlayableAmount,
      commissionPercent
    };
  }
  return {};
};
setGlobal('svs.components.marketplaceRetailAdminData.createGameTemplate.helpers.calculateCommission', calculateCommission);
setGlobal('svs.components.marketplaceRetailAdminData.createGameTemplate.helpers.calculateMaxPlayableAmount', calculateMaxPlayableAmount);
setGlobal('svs.components.marketplaceRetailAdminData.createGameTemplate.helpers.calculateFractionPrice', calculateFractionPrice);

 })(window);