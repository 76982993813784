(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/create-game-template-validator.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/create-game-template/assets/javascripts/create-game-template-validator.js');
"use strict";


const MANDATORY_PARAMETERS = ['fractionPrice', 'ownerId', 'maxFractions', 'name', 'productName', 'maxPlayableAmount'];
function validateName(input) {
  return /^[a-z0-9åäö\s-]{2,30}$/i.test(input);
}
const isValid = gameTemplate => {
  const template = gameTemplate || {};
  const missingMandatoryField = MANDATORY_PARAMETERS.find(name => !template[name]);
  return !missingMandatoryField && !Object.keys(template).find(name => !isFieldValid(name, template[name]));
};
const isFieldValid = (name, value) => !validateField(name, value);
const validateField = (name, value) => {
  let error = '';
  switch (name) {
    case 'maxPlayableAmount':
      {
        if (value < 20) {
          error = 'Minsta systemstorlek är 20 kr';
        } else if (value > 42000) {
          error = 'Största systemstorlek är 42.000 kr';
        } else if (Number.isNaN(Number(value))) {
          error = 'Systemstorlek får endast innehålla siffror';
        } else {
          error = '';
        }
        break;
      }
    case 'fractionPrice':
      error = value >= 10 && value <= 10000 ? '' : 'Andelspris måste vara mellan 10 - 10.000 kr';
      break;
    case 'maxFractions':
      error = value >= 2 && value <= 50 ? '' : 'Antalet andelar måste vara mellan 2 - 50';
      break;
    case 'name':
      error = validateName(value) ? '' : 'Namnet måste vara mellan 2 till 30 tecken långt och får bara innehålla a-ö, siffror, blanksteg och bindestreck.';
      break;
    default:
      break;
  }
  return error;
};
setGlobal('svs.components.marketplaceRetailAdminData.createGameTemplate.validator', {
  isValid,
  validateField
});

 })(window);